import { createSlice } from "@reduxjs/toolkit";

// Redux slice
const initialState: any = {
    openModals: {}, // Keyed by modal ID
};

export const createClientSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        openModal: (state, action) => {
            const { modalId } = action.payload;
            state.openModals[modalId] = true;
        },
        closeModal: (state, action) => {
            const { modalId } = action.payload;
            state.openModals[modalId] = false;
        },
    },
});

export const { openModal, closeModal } = createClientSlice.actions;
export default createClientSlice.reducer;